<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>{{ $route.name }}</h4></el-row
            >
          </el-col>
          <el-col :md="16">
            <el-row type="flex" justify="end" align="center" :gutter="8">
              <el-date-picker
                v-model="selectedDateRange"
                type="daterange"
                range-separator="até"
                start-placeholder="Data Inicial"
                end-placeholder="Data Final"
                size="medium"
                format="DD/MM/YYYY"
              />
              <el-button
                type="primary"
                size="medium"
                plain
                @click="() => createPDF({ created_at: false }, 'date')"
                >Imprimir</el-button
              >
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-empty
        v-if="!orders"
        description="Utilize os filtros para buscar os pagamentos"
      ></el-empty>
      <div v-for="(orders, client) in GroupedOrders" :key="client" v-else>
        <h2 class="el-table__subheader_name">{{ client }}</h2>
        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          show-summary
          :summary-method="(v) => calcSummary(v, orders)"
          sum-text="Total do cliente"
          :data="orders"
          style="width: 100%; z-index: 0"
        >
          <el-table-column label="pedido" prop="code"></el-table-column>
          <el-table-column prop="client.name" label="cliente">
            <template #default="{ row: order }">
              {{ order.client?.legal?.social_name || order.client?.name }}
            </template>
          </el-table-column>

          <el-table-column
            label="dinheiro"
            :formatter="
              (order) =>
                currencyFormatterBlankZero.format(
                  sumByPaymentMethod([order], 'money')
                )
            "
          ></el-table-column>

          <el-table-column
            label="crediário"
            :formatter="
              (order) =>
                currencyFormatterBlankZero.format(
                  sumByPaymentMethod([order], 'cash')
                )
            "
          ></el-table-column>

          <el-table-column
            label="crédito"
            :formatter="
              (order) =>
                currencyFormatterBlankZero.format(
                  sumByPaymentMethod([order], 'credit')
                )
            "
          ></el-table-column>

          <el-table-column
            label="débito"
            :formatter="
              (order) =>
                currencyFormatterBlankZero.format(
                  sumByPaymentMethod([order], 'debit')
                )
            "
          ></el-table-column>

          <el-table-column
            label="cheque"
            :formatter="
              (order) =>
                currencyFormatterBlankZero.format(
                  sumByPaymentMethod([order], 'check')
                )
            "
          ></el-table-column>

          <el-table-column
            label="pix"
            :formatter="
              (order) =>
                currencyFormatterBlankZero.format(
                  sumByPaymentMethod([order], 'pix')
                )
            "
          ></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderService from "../services/orders";
import { generatePDF } from "../services/reports";
import {
  dateFormatter,
  currencyFormatterBlankZero,
  timeFormatter,
  dateFormatterShort,
  dateFormatterLong,
} from "../utils/formatters";
import { notifyError, notifySuccess } from "../utils/notifiers";

export default {
  name: "OrdersCompletedDailyPage",
  data: () => ({
    hasError: false,
    isLoading: false,
    orders: null,
    currencyFormatterBlankZero,
    dateFormatter,
    selectedDateRange: null,
    allowedFields: {},
    timeFormatter,
    dateFormatterShort,
    dateFormatterLong,
  }),
  computed: {
    Orders() {
      return this.orders || [];
    },
    ExpandOrderPayments() {
      return this.orders
        ?.flatMap((order) =>
          order?.payments?.map((payment) => ({ ...order, ...payment })).flat()
        )
        ?.filter(({ paid_at }) => !!paid_at);
    },
    GroupedOrders() {
      return this.groupByClientRoute();
    },
  },
  watch: {
    selectedDateRange() {
      this.fetchOrders(true);
    },
  },
  methods: {
    calcSummary({ columns }, orders = []) {
      return columns?.map((column, id) => {
        if (!id) return "Total de recebimentos";

        if (id === 2)
          return this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "money")
          );

        if (id === 3)
          return this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "cash")
          );

        if (id === 4)
          return this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "credit")
          );

        if (id === 5)
          return this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "debit")
          );

        if (id === 6)
          return this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "check")
          );

        if (id === 7)
          return this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "pix")
          );

        return "";
      });
    },
    groupByClientRoute() {
      const groupedOrders = this.ExpandOrderPayments?.reduce((t, o) => {
        const paidAt = this.dateFormatter.format(new Date(o.paid_at));
        if (t[paidAt]) t[paidAt].push(o);
        else t[paidAt] = [o];

        return t;
      }, {});

      return groupedOrders;
    },
    allowUserUpdate(uid) {
      this.allowedFields[uid] = true;
    },
    canUpdate({ uid }) {
      return !!this.allowedFields[uid];
    },
    async updateOrder(orderData) {
      if (orderData) {
        const { order, error } = await OrderService(orderData.uid).update(
          orderData
        );

        if (error)
          notifyError("Ocorreu um erro ao atualizar o vínculo", error.message);
        else {
          notifySuccess(order.message);
        }
      }

      this.allowedFields[orderData.uid] = false;
    },
    executeUserCommand(command) {
      switch (command) {
        case "OrderByCreatedAt":
          this.createPDF({ created_at: false }, "date");
          break;
        case "OrderByInvoiceId":
          this.createPDF({ invoice_id: true }, "number");
          break;
      }
    },
    convertOrderValuesByType(first, second, key, orderByType) {
      let f, s;
      switch (orderByType) {
        case "date":
          f = new Date(first[key]);
          s = new Date(second[key]);
          break;
        case "number":
          f = Number(first[key]);
          s = Number(second[key]);
          break;
        default:
          f = first[key];
          s = second[key];
      }

      return { f, s };
    },
    generatePDFContent(orderBy, orderByType) {
      return Object.values(this.GroupedOrders).map((orders) => {
        const mappedOrders = orders
          .sort((first, second) => {
            const [[key, asc]] = Object.entries(orderBy);

            const { f, s } = this.convertOrderValuesByType(
              first,
              second,
              key,
              orderByType
            );

            if (asc) return f < s ? -1 : 1;
            else f > s ? -1 : 1;
          })
          .map((orderPayment) => [
            orderPayment.code?.toString() || " ",
            orderPayment.client?.legal?.social_name ||
              orderPayment.client?.name ||
              " ",
            this.currencyFormatterBlankZero.format(
              this.sumByPaymentMethod([orderPayment], "money")
            ),
            this.currencyFormatterBlankZero.format(
              this.sumByPaymentMethod([orderPayment], "cash")
            ),
            this.currencyFormatterBlankZero.format(
              this.sumByPaymentMethod([orderPayment], "credit")
            ),
            this.currencyFormatterBlankZero.format(
              this.sumByPaymentMethod([orderPayment], "debit")
            ),
            this.currencyFormatterBlankZero.format(
              this.sumByPaymentMethod([orderPayment], "check")
            ),
            this.currencyFormatterBlankZero.format(
              this.sumByPaymentMethod([orderPayment], "pix")
            ),
          ]);

        mappedOrders.push([
          "Total de recebimentos",
          " ",
          this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "money")
          ),
          this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "cash")
          ),
          this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "credit")
          ),
          this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "debit")
          ),
          this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "check")
          ),
          this.currencyFormatterBlankZero.format(
            this.sumByPaymentMethod(orders, "pix")
          ),
        ]);

        return mappedOrders;
      });
    },
    generateTableHeaders() {
      return [
        [
          "pedido",
          "cliente",
          "dinheiro",
          "crediário",
          "crédito",
          "débito",
          "cheque",
          "pix",
        ],
      ];
    },
    createPDF(orderBy, orderByType) {
      const headers = this.generateTableHeaders();
      const content = this.generatePDFContent(orderBy, orderByType);

      const headerMessage = `Relatório de Pedidos - ${this.dateFormatter.format(
        this.day
      )}`;

      generatePDF({
        fileName: `Relatório de Pedidos - ${this.dateFormatter
          .format(this.day)
          .replace(/\//g, "-")}.pdf`,
        headerMessage,
        headers,
        content,
        orientation: "landscape",
        isMultipleTables: true,
        addTableSummary: true,
        addTableTitle: true,
        defaultFontSize: 8,
      });
    },
    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production", "delayed"].includes(order.status)
      )
        order.status = "delayed";

      if (order.deleted_at) order.status = "canceled";
      return ORDER_STATUS_TYPES[order.status];
    },
    async fetchOrders(showLoading = false) {
      if (showLoading) this.isLoading = true;

      const [paid_at_start, paid_at_end] = this.selectedDateRange;

      paid_at_start.setHours(0, 0, 0, 0);
      paid_at_end.setHours(23, 59, 59, 999);

      const { orders } = await OrderService().index({
        status: "paid,completed",
        payment_methods: "money,cash,credit,debit,check,pix",
        paid_at_start,
        paid_at_end,
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
    sumAmountPaymentOrders(payments) {
      return payments?.reduce((t, { amount }) => t + Number(amount), 0) || 0;
    },
    sumByPaymentMethod(payments, method) {
      return payments.reduce(
        (t, p) =>
          (t +=
            !!p.paid_at && p.payment_method === method ? Number(p.amount) : 0),
        0
      );
    },
    sumPaidOrderPayments(payments) {
      return (
        payments?.reduce(
          (t, { amount, paid_at }) => (paid_at ? t + Number(amount) : t),
          0
        ) || 0
      );
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  delayed: "danger",
  canceled: "danger",
  delivered: "success",
  completed: "info",
  finalized: "success",
};
</script>
<style scoped>
.el-card {
  width: 100%;
}

.mt-1 {
  margin-top: 5px;
}
</style>
